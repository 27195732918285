import { WebsiteConf } from '@config/website'
import { LazyScroll } from '@headless/ui'
import { Helmet } from 'react-helmet'

import CmsBanner from './CmsBanner'
import CmsProduct from './CmsProduct'
import { StyledHomePage } from './styled'

const HomePage = ({ children }: any) => {
  const { isCustomized } = WebsiteConf
  return isCustomized ? (
    <StyledHomePage>
      <Helmet>
        <meta name="title" content="Women's Shoes & Boots – ZURIN" />
        <meta
          name="keywords"
          content="Women's Shoes, Women's Boots, Women's Footwear"
        />
        <meta
          name="description"
          content="ZURIN offers a refined collection of women's footwear, discover sneakers, flats, clogs, loafers, leather boots, oxfords, and more. Enjoy free shipping on all orders."
        />
        <title>Women's Shoes & Boots – ZURIN</title>
      </Helmet>
      <CmsBanner />
      <div className="container">
        <LazyScroll>
          <CmsProduct />
        </LazyScroll>
        {children}
        {/* <LazyScroll>
          <CmsGallery />
        </LazyScroll> */}
      </div>
    </StyledHomePage>
  ) : (
    <>{children}</>
  )
}

export default HomePage
